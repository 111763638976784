import React from 'react'
import MUKHVAS_DANI from './MUKHVAS_DANI';
import SINK_COUPLING from './SINK_COUPLING';
import KITCHEN_STAND from './KITCHEN_STAND';
import ELECTRIC_BOARD from './ELECTRIC_BOARD';
import PVC_PIPE_CONNECTER from './PVC_PIPE_CONNECTOR';


 function Designs() {
    document.title='Our Designs';
    ;
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div className="container">
        <MUKHVAS_DANI/>
        <SINK_COUPLING/>
        <ELECTRIC_BOARD/>
        <KITCHEN_STAND/>
        <PVC_PIPE_CONNECTER/>
    </div>
  )
}

export default Designs;
