import React from 'react'
// import { useEffect } from 'react'

export default function Contact() {
    document.title='contact us';
    React.useEffect(() => {
        window.scrollTo(0, 0);
       
      }, []);
      
    return (
        <div>
              <div class="container-fluid contactt page-header py-5 mb-5">
        <div class="container py-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">Contact</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                
            </nav>
        </div>
    </div>
            <div className="container-fluid bg-light overflow-hidden px-lg-0" style={{ margin: '6 rem ' }}>
                <div className="container contact px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-4 contact-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 ps-lg-0">
                                <div className="info align-items-center">
                                    <div className="address">
                                        <h4><i className="bi bi-geo-alt"></i></h4>
                                        <h4>Location:</h4>
                                        <p ><h6 style={{ color: '#AB7442' }}> Shivam Complex, <br/>
                                            5-Lati Plot Main Road<br/>
                                            Moon nagar Morbi 363641 </h6> <br/></p>
                                    </div>
                                    <div className="email">
                                        <h4><i className="bi bi-envelope"></i></h4>
                                        <h4>Email:</h4>
                                        <a href="mailto:saradvajayesh01@gmail.com"> <h6 style={{ color: '#AB7442' }}> saradvajayesh01@gmail.com</h6></a>
                                    </div><br/>
                                    <div className="phone">
                                        <h4><i className="bi bi-phone"></i></h4>
                                        <h4>Call:</h4>
                                        <a href="tel:+91 9726952170"><h6 style={{color: '#AB7442'}}>  +91 9726952170 </h6></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 pe-lg-0 reactcontect1">
                            <div className="position-relative h-100">
                            <iframe class="position-absolute w-100 h-100 reactabout3" 
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7355.337954986296!2d70.8234085!3d22.814726500000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1647779676602!5m2!1sen!2sin"  title="myFrame"  allowfullscreen="" loading="lazy">
                        </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
