import React from "react";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  return (

    <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
      <NavLink
        to="/"
        className="navbar-brand d-flex align-items-center px-4 px-lg-5"
      >
        <img src="img/logo/logo.png" alt="" className="j" />
      </NavLink>
      <button
        type="button"
        class="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto p-4 p-lg-0">
          <NavLink to="/" className="nav-item nav-link ">
            Home
          </NavLink>
          <NavLink to="/about" className="nav-item nav-link">
            About
          </NavLink>
          <div class="nav-item dropdown">
            <NavLink to="/design" className="nav-link">
              Designs
            </NavLink>
            <div class="dropdown-menu fade-up m-0">
              <a class="dropdown-item">
                <NavLink to="/MUKHVAS_DANI" class="dropdown-item">
                  MUKHVAS DANI
                </NavLink>
              </a>
              <a class="dropdown-item">
                <NavLink to="/SINK_COUPLING" class="dropdown-item">
                  SINK COUPLING
                </NavLink>
              </a>
              <a class="dropdown-item">
                <NavLink to="/ELECTRIC_BOARD" class="dropdown-item">
                  ELECTRIC BOARD
                </NavLink>
              </a>
              <a class="dropdown-item">
                <NavLink to="/KITCHEN_STAND" class="dropdown-item">
                  KITCHEN STAND
                </NavLink>
              </a>
              <a class="dropdown-item">
                <NavLink to="/PVC_PIPE_CONNECTOR" class="dropdown-item">
                  PVC PIPE CONNECTOR
                </NavLink>
              </a>
            </div>
          </div>
          <NavLink to="/contact" className="nav-item nav-link">
            Contact
          </NavLink>
        </div>
      </div>
    </nav>
  );
}
