import React from "react";

function About() {
  return (
    <>
 
    <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">About Us</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li className="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                    <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                </ol>
            </nav>
        </div>
    </div>


    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 ps-lg-0 reactabout1">
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100 h-70 reactabout2" src="img/Gif/gif.gif" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="section-title text-start">
                                    <h1 className="display-5 mb-4">About Us</h1>
                                </div>
                                <p className="mb-4 pb-2">
                                    Dharati Engineering Was Established In The Year 2010 In Morbi
                                                      Town By Amarshibhai Who Have Nearly 15 Years Of Experience In
                                                      The Field Of Reverse Engineering,Product Design,mould And Die
                                                      Design.aiming To Cater To The Fast Growing Plastic ,the
                                                      Services Started With Product Design And Mould Design For
                                                      Clock Industries In Morbi And Went On To Expanding Its Base To
                                                      Ceramic Design,ceramic Die Manufacturing,reverse
                                                      Engineering,product Design And Mould Design Of Kitchen
                                                      Wares,bath Wares,electricals And Many More.
                                </p>
                                <div className="row g-4 mb-4 pb-2"></div>
                                <a href="about.html" className="btn  py-3 px-5">
                                    Explore More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}
export default About;
