import React from "react";
import {useEffect, useState} from "react";

function KITCHEN_STAND() {
    const [data, setData] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    // setLoding(false);
    useEffect(() => {
        fetch("https://dharatiengineering.in/ad_dharati/Api/Product/Images/KITCHEN-STAND", setisLoading(true)).then((res) => {
            return res.json();
        }).then((res) => {
            setData(res.data);
            setisLoading(false);
        });
    }, []);

    const KitchenStandDetails = data.map((kitchenstand) => {
        return (
            <>
                <div className="portfolio-item filter-web col-lg-3 col-sm-6">
                    <div className="portfolio-wrap">
                        <img src={
                                "https://dharatiengineering.in/ad_dharati/Images/product/" + kitchenstand.Path
                            }
                            className="img-fluid shadow-4"
                            alt="Latest Corporation img kichan sink"/>
                    </div>
                </div>
            </>
        );
    });


    return (
        <>
            <section id="portfolio" className="portfolio">
                <div className="container">
                    <div>
                        <hr/>
                        <h2 className="text-center"style={{ color : '#AB7442'}}>KITCHEN STAND</h2>
                        <hr/>
                    </div>
                    <div className="row portfolio-container animate__animated animate__fadeInDown">
                        {KitchenStandDetails}
                        </div>
                </div>
            </section>
        </>
    );

}

export default KITCHEN_STAND;