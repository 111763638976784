import React from "react";
import { Link } from "react-router-dom";

export default function Foter() {
  return (
    <>
      <footer
        className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6 footer-links">
              <h4 style={{ color: "aliceblue" }}>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi "></i> <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="bi "></i> <Link to="/about">About us</Link>
                </li>
                <li>
                  <i className="bi "></i> <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4 style={{ color: "aliceblue" }}>Designs</h4>
              <ul>
                <li>
                  <i className="bi "></i>{" "}
                  <Link to="/MUKHVAS_DANI">Mukhavas dani</Link>
                </li>
                <li>
                  <i className="bi "></i>{" "}
                  <Link to="/SINK_COUPLING">Sink coupling</Link>
                </li>
                <li>
                  <i className="bi "></i>{" "}
                  <Link to="/ELECTRIC_BOARD">Electric board</Link>
                </li>
                <li>
                  <i className="bi "></i>{" "}
                  <Link to="/KITCHEN_STAND">Kitchen stand</Link>
                </li>
             
                <li>
                  <i className="bi "></i>{" "}
                  <Link to="/PVC_PIPE_CONNECTER">Pvc pipe connector</Link>
                </li>
              
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-contact">
              <h4 style={{ color: "aliceblue" }}>Contact Us</h4>
              <p style={{ color: "#AB7442" }}>
                Shivam Complex, <br />
                5-Lati Plot Main Road
                <br />
                Moon nagar Morbi 363641 <br />
              </p>
              <strong>Phone:</strong>
              <a href="tel:+91 9726952170"> +91 9726952170</a>
              <br />

              <strong>Email:</strong>
              <a href="mailto:saradvajayesh01@gmail.com">
                {" "}
                saradvajayesh01@gmail.com
              </a>
              <br />
              <br />
              <div className="social-links">
                <a href="tel:+91 9726952170" className="telephone">
                  <i className="bi bi-telephone">&nbsp;</i>{" "}
                </a>
                <a
                  href="https://www.facebook.com/jayesh.saradva.3"
                  className="facebook"
                >
                  <i className="bi bi-facebook">&nbsp;</i>
                </a>
                <a
                  href="https://wa.me/message/C66IHZFCCI4IO1"
                  className="whatsapp"
                >
                  <i className="bi bi-whatsapp">&nbsp;</i>
                </a>
                <a href="mailto:saradvajayesh01@gmail.com" className="Email">
                  <i className="bi bi-envelope"></i>
                </a>
              </div>
            </div>
            <hr />
          </div>
          <div className="container">
            <p className="text-center" style={{ margin: "auto" }}>
                © Copyright 
              <a href="/"> <b>Dharati Engineering. </b></a>
               All Rights Reserved
            </p>
          </div>
        </div>
       
      </footer>
      
      <a
        href="https://wa.me/message/C66IHZFCCI4IO1"
        class="btn btn-lg btn-primary btn-lg-square rounded-0 back-to-wh"
      >
        <i class="bi bi-whatsapp"></i>
      </a>
      <br></br>
      <a
        href="/"
        class="btn btn-lg btn-primary btn-lg-square rounded-0 back-to-top"
      >
        <i class="bi bi-arrow-up"></i>
      </a>


    </>
  );
}
