import './App.css';
import React from "react";
import Navbar from './componants/Navbar';
import Foter from './componants/Foter';
import About from './componants/About';
import Designs from './componants/Designs';
import Contact from './componants/Contact';
import Home from './componants/Home';
import PVC_PIPE_CONNECTER from './componants/PVC_PIPE_CONNECTOR';
import MUKHVAS_DANI from './componants/MUKHVAS_DANI';
import SINK_COUPLING from './componants/SINK_COUPLING';
import ELECTRIC_BOARD from './componants/ELECTRIC_BOARD';
import KITCHEN_STAND from './componants/KITCHEN_STAND';



// https://stackoverflow.com/questions/34418254/how-do-i-add-an-active-class-to-a-link-from-react-router



import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";





function App() {
 

  return (
    <>
      <div className="App">
        <Router>
          <Navbar/>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/design" element={<Designs />} />
            <Route path="/about" element={<About />} />
            <Route path="/MUKHVAS_DANI" element={<MUKHVAS_DANI />} />
            <Route path="/SINK_COUPLING" element={<SINK_COUPLING />} />
            <Route path="/ELECTRIC_BOARD" element={<ELECTRIC_BOARD />} />
            <Route path="/KITCHEN_STAND" element={<KITCHEN_STAND />} />
            <Route path="/PVC_PIPE_CONNECTOR" element={<PVC_PIPE_CONNECTER />} />
          </Routes>
         
          <Foter />
        </Router>
       

      </div>
    </>
  );
}

export default App;
